import { Component, Input, OnInit } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { flatMap, tap } from 'rxjs/operators';
import { privilegeOfAdmin } from 'src/app/components/compounds/permissions/role-check';
import funnelParams from 'src/app/helpers/parsers/funnel';
import { AccountService } from 'src/app/services/account.service';
import { CommunicationService } from 'src/app/services/account/communication.service';
import { FeatureService } from 'src/app/services/account/feature.service';
import { SubscriptionService } from 'src/app/services/account/subscription.service';
import { CustomerlyService } from 'src/app/services/integrations/customerly.service';
import { IntercomService } from 'src/app/services/integrations/intercom.service';
import { MeService } from 'src/app/services/me.service';
import { ToastService } from 'src/app/services/toast.service';
import { AryelAccountRole } from 'src/modules/type-definitions/account';
import { AccountInterface } from 'src/types/entities/account';

@Component({
  selector: 'aryel-choose-account',
  templateUrl: './choose-account.component.html',
  styleUrls: ['./choose-account.component.scss'],
})
export class ChooseAccountComponent implements OnInit {
  @Input() accounts: {
    ownedAccount: AccountInterface;
    managedAccounts: AccountInterface[];
  } = null;

  ROLE_LABELS = {
    owner: 'Owner',
    admin: 'Collaborator',
    manager: 'Collaborator',
    author: 'Collaborator',
    viewer: 'Collaborator',
  };

  hide: boolean = true;

  get currentAccount() {
    return this.accountService.currentAccount;
  }

  get me() {
    return this.meService.me;
  }

  get backState() {
    return this.transition.params().backState || null;
  }

  constructor(
    private accountService: AccountService,
    private meService: MeService,
    private stateService: StateService,
    private transition: Transition,
    private toastService: ToastService,
    private featureService: FeatureService,
    private subscriptionService: SubscriptionService,
    private communicationService: CommunicationService,
    private intercomService: IntercomService,
    private customerlyService: CustomerlyService
  ) {}

  ngOnInit() {
    if (this.accounts.ownedAccount) {
      this.intercomService.updateCompany(this.me, {
        company_id: this.accounts.ownedAccount._id,
        name: this.accounts.ownedAccount.company.name,
      });
      this.customerlyService.updateCompany(this.me, {
        company_id: this.accounts.ownedAccount._id,
        name: this.accounts.ownedAccount.company.name,
      });
    }
    if (this.accounts.managedAccounts.length) {
      this.intercomService.updateCompanies(
        this.me,
        this.accounts.managedAccounts.map((account) => {
          return {
            company_id: account._id,
            name: account.company.name,
          };
        })
      );
      this.customerlyService.updateCompanies(
        this.me,
        this.accounts.managedAccounts.map((account) => {
          return {
            company_id: account._id,
            name: account.company.name,
          };
        })
      );
    }

    if (!this.accounts.managedAccounts?.length && this.accounts.ownedAccount) {
      this.operateOn(this.accounts.ownedAccount);
    } else {
      this.hide = false;
    }
  }

  operateOn(account: AccountInterface): void {
    this.accountService
      .setCurrentAccount(account)
      .pipe(
        flatMap(() => this.featureService.setAvailableFeatures()),
        flatMap(() => this.subscriptionService.setCurrentSubscription(account.subscription)),
        tap(() => this.communicationService.init()),
        tap(() => this.intercomService.setCurrentAccount(this.me, this.currentAccount)),
        tap(() => this.customerlyService.setCurrentAccount(this.me, this.currentAccount))
      )
      .subscribe(
        (_) => {
          if (!privilegeOfAdmin(this.me._id, this.currentAccount)) {
            return this.stateService.go('private.home');
          }
          this.accountService.pendingSubscriptions().subscribe(
            (pendingSubscriptions) => {
              if (pendingSubscriptions.length) {
                this.accountService.setPendingSubscription(pendingSubscriptions[0]);
              } else {
                this.checkUpgrade();
              }
            },
            (_) => this.checkUpgrade()
          );
        },
        (err) => this.toastService.addToast(err)
      );
  }

  private checkUpgrade() {
    if (this.stateService.params.plan || this.stateService.params.coupon) {
      this.stateService.go('private.billing.upgrade-plan', funnelParams(this.stateService));
    } else {
      this.stateService.go('private.home');
    }
  }

  userRole(users: Array<{ role: AryelAccountRole | 'owner'; user: string }>) {
    return this.ROLE_LABELS[users.find((x) => x.user === this.me._id).role];
  }

  public logOut(): void {
    this.stateService.go('private.sign-out');
  }
}
