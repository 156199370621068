import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aryel-mobile-lock',
  templateUrl: './mobile-lock.component.html',
  styleUrls: ['./mobile-lock.component.scss'],
})
export class MobileLockComponent implements OnInit {
  @Input() disabled: boolean;

  isDesktop: boolean = null;
  constructor() {
    if (screen && window && screen.width / window.devicePixelRatio >= 450) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }

  ngOnInit(): void {}
}
