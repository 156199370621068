import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'aryel-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss'],
})
export class FileChooserComponent implements AfterViewInit {
  @Input() accept: string = '*';

  @Input() sizeLimit: number = 20971520;
  @Input() sizeLimitLabel: string = '20MB per image';

  @Output() onSelect: EventEmitter<File[]> = new EventEmitter();
  @Output() onConfirm: EventEmitter<File[]> = new EventEmitter();
  @Output() onUndo: EventEmitter<void> = new EventEmitter();

  @ViewChild('uploadBox') uploadBox: ElementRef<HTMLElement>;
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() showPreview: boolean;
  imagePreview: {
    src: string;
    files: File[];
  } = null;

  dragOver: boolean = false;

  constructor(private toastService: ToastService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.uploadBox && this.uploadBox.nativeElement) {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
          this.uploadBox.nativeElement.addEventListener(eventName, this.dragDropPrevent, false);
        });
        ['dragenter', 'dragover'].forEach((eventName) => {
          this.uploadBox.nativeElement.addEventListener(eventName, ($event) => this.dragDropOver($event, this), false);
        });
        this.uploadBox.nativeElement.addEventListener('dragleave', ($event) => this.dragDropLeave($event, this), false);
        this.uploadBox.nativeElement.addEventListener('drop', ($event) => this.dragDropUpload($event, this), false);
      }
    });
  }

  ngOnDestroy() {
    if (this.uploadBox && this.uploadBox.nativeElement) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        this.uploadBox.nativeElement.removeEventListener(eventName, this.dragDropPrevent, false);
      });
      ['dragenter', 'dragover'].forEach((eventName) => {
        this.uploadBox.nativeElement.removeEventListener(eventName, ($event) => this.dragDropOver($event, this), false);
      });
      this.uploadBox.nativeElement.removeEventListener('dragleave', ($event) => this.dragDropLeave($event, this), false);
      this.uploadBox.nativeElement.removeEventListener('drop', ($event) => this.dragDropUpload($event, this), false);
    }
  }

  private dragDropPrevent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  private dragDropOver(e, vm) {
    vm.dragOver = true;
  }

  private dragDropLeave(e, vm) {
    vm.dragOver = false;
  }

  private dragDropUpload(e, vm) {
    vm.dragOver = false;
    vm.onFileSelect({
      target: {
        files: e.dataTransfer.files,
      },
    });
  }

  private simulateClick(elem: any) {
    const mouseevent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    return !elem.dispatchEvent(mouseevent);
  }

  openChooseFile() {
    this.simulateClick(this.fileInput.nativeElement);
  }

  onFileSelect(data: any) {
    this.imagePreview = null;

    const list = data.target.files as FileList;
    if (!list.length) {
      return;
    }

    let error = false;
    const files = Array.from(list);
    for (const file of files) {
      if (!this.checkFileSize(file) || !this.checkFileExt(file)) {
        error = true;
        break;
      }
    }
    if (!error) {
      this.onSelect.emit(files);
      if (this.showPreview) {
        this.generatePreview(files);
      }
    }
  }

  private checkFileSize(file: File): boolean {
    if (this.sizeLimit > 0) {
      if (file.size && file.size > this.sizeLimit) {
        this.toastService.addToast({
          title: `${file.name} is too big`,
          text: `Size limit is ${this.sizeLimitLabel}.`,
          status: 'error',
        });
        return false;
      }
    }
    return true;
  }

  private checkFileExt(file: File): boolean {
    if (this.accept !== '*') {
      const data = file.name.split('.');
      if (data) {
        const ext = data[data.length - 1];
        const accepted = this.accept.split(',').map((x) => x.trim().replace(/\./g, ''));
        if (!accepted.includes(ext.toLowerCase())) {
          this.toastService.addToast({
            title: `${file.name} extension is invalid`,
            text: `Accepted extensions: ${this.accept}`,
            status: 'error',
          });
          return false;
        }
      }
    }
    return true;
  }

  private async generatePreview(files: File[]) {
    try {
      this.imagePreview = null;

      const imagePreviewSrc = await this.getPreviewImage(files[0]);
      this.imagePreview = {
        src: imagePreviewSrc,
        files,
      };
    } catch (err) {
      this.toastService.addToast({
        title: 'Warning',
        text: 'It was not possibile to render the image preview',
        status: 'warning',
      });
    }
  }

  private getPreviewImage(file: File): Promise<string> {
    const reader = new FileReader();

    const result: Promise<string> = new Promise((resolve, reject) => {
      reader.onload = (event) => {
        resolve(event.target.result as string);
      };
      reader.onerror = reject;
    });

    reader.readAsDataURL(file);

    return result;
  }
}
