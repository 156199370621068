<div class="submenu">
  <div
    class="submenu__opener"
    [class.open]="showSubMenu && !disabled"
    (click)="subMenuTrigger()"
    [class.hide]="hasContent"
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    #content
    class="submenu__content-opener"
    (click)="subMenuTrigger()"
    [class.hidden]="!hasContent"
  >
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="showSubMenu && !disabled"
    [class.submenu__content--large]="large"
    class="submenu__content scrollbar scrollbar--v"
    [style.max-height]="height ? height + 'px' : 'unset'"
    [class.submenu__content--far]="far"
    [ngClass]="['submenu__content--' + anchor]"
    [@openClose]
    [style.width]="width + 'px'"
    (mouseleave)="subMenuTrigger()"
  >
    <ng-container *ngIf="options.length">
      <ng-container
        *ngFor="let option of options"
        [ngTemplateOutlet]="big"
        [ngTemplateOutletContext]="{ option: option }"
      >
      </ng-container>
    </ng-container>
    <ng-container *ngIf="secondaryOptions.length">
      <div class="submenu__divider"></div>
      <ng-container
        *ngFor="let option of secondaryOptions"
        [ngTemplateOutlet]="small"
        [ngTemplateOutletContext]="{ option: option }"
      >
      </ng-container>
    </ng-container>
    <ng-container *ngIf="footerOptions.length">
      <div class="submenu__divider"></div>
      <ng-container
        *ngFor="let option of footerOptions"
        [ngTemplateOutlet]="big"
        [ngTemplateOutletContext]="{ option: option }"
      >
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #big let-option="option">
  <div
    class="grid-x pointer submenu__voice align-middle"
    [class.submenu__voice--disabled]="option.disabled"
    (click)="!option.disabled ? select(option) : null"
  >
    <div class="cell shrink submenu__voice-icon" *ngIf="option.icon">
      <span
        [ngClass]="['color-' + option.color]"
        class="material-symbols-outlined"
      >
        {{ option.icon }}
      </span>
    </div>
    <div class="cell auto submenu__voice-text">
      <p
        [ngClass]="['color-' + option.color]"
        [class.one-line]="option.oneLine"
      >
        {{ option.text }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #small let-option="option">
  <p
    *ngIf="!option.href || option.disabled"
    class="submenu__small-voice pointer"
    [class.submenu__small-voice--disabled]="option.disabled"
    [class.one-line]="option.oneLine"
    (click)="!option.disabled ? select(option) : null"
  >
    {{ option.text }}
  </p>
  <a
    *ngIf="option.href && !option.disabled"
    [href]="option.href"
    [target]="option.target || '_self'"
    class="submenu__small-voice pointer p link"
    [class.one-line]="option.oneLine"
    [class.submenu__small-voice--disabled]="option.disabled"
  >
    {{ option.text }}
  </a>
</ng-template>
