<form
  *ngIf="!emailSent"
  [formGroup]="form"
  (ngSubmit)="signinRequestSubmit($event)"
  class="view"
>
  <div class="view__card">
    <aryel-card>
      <ng-container head>
        <div class="text-center">
          <img src="assets/img/brand/logo.png" class="logo" />
        </div>
      </ng-container>
      <h1 class="text-center view__title">Authenticate</h1>
      <div class="view__form">
        <aryel-form-field placeholder="Email">
          <input
            aryelFormField
            type="text"
            [formControl]="form.controls.email"
          />
        </aryel-form-field>
      </div>
      <div class="spacer spacer--v spacer--xlarge"></div>
      <aryel-button type="submit" [full]="true"> Submit </aryel-button>
    </aryel-card>
  </div>
</form>

<form
  *ngIf="emailSent"
  [formGroup]="form"
  (ngSubmit)="signinSubmit($event)"
  class="view"
>
  <div class="view__card">
    <aryel-card>
      <ng-container head>
        <div class="text-center">
          <img src="assets/img/brand/logo.png" class="logo" />
        </div>
      </ng-container>
      <h1 class="text-center view__title">Authenticate</h1>
      <div class="view__form">
        <aryel-form-field placeholder="Code">
          <input
            aryelFormField
            type="text"
            [formControl]="form.controls.code"
          />
        </aryel-form-field>
      </div>
      <div class="spacer spacer--v spacer--xlarge"></div>
      <aryel-button type="submit" [full]="true"> Submit </aryel-button>
    </aryel-card>
  </div>
</form>
