<div class="header">
  <div class="grid-container grid-container--header">
    <div class="grid-x align-middle header__inner">
      <div uiSref="private.home" class="cell shrink pointer header__logo">
        <div @logo *ngIf="showLogo">
          <img src="assets/img/brand/logo.png" class="logo" />
          <img src="assets/img/brand/logo-white.png" class="logo" />
        </div>
      </div>
      <div class="cell auto header__nav">
        <ng-container *ngFor="let nav of navigation; let i = index">
          <div *ngIf="i !== 0" class="spacer spacer--xxlarge spacer--h"></div>
          <aryel-nav-element [nav]="nav"></aryel-nav-element>
        </ng-container>
      </div>
      <div
        class="cell shrink align-self-middle header__tour"
        *ngIf="tourExists"
        (click)="startTour()"
      >
        <aryel-tooltip text="Quick tips" position="bottom" width="5rem">
          <span class="material-symbols-outlined"> tips_and_updates </span>
        </aryel-tooltip>
      </div>
      <div class="cell shrink align-self-middle header__theme">
        <span
          @colorMode
          *ngIf="currentThemeMode === 'dark'"
          class="material-symbols-outlined pointer not-selectable"
          (click)="setTheme()"
        >
          brightness_5
        </span>

        <span
          @colorMode
          *ngIf="currentThemeMode === 'color'"
          class="material-symbols-outlined pointer not-selectable"
          (click)="setTheme()"
        >
          dark_mode
        </span>
      </div>
      <div class="cell shrink align-self-middle header__notifications">
        <aryel-notifications-menu>
          <span class="material-symbols-outlined"> notifications </span>
        </aryel-notifications-menu>
      </div>
      <aryel-submenu
        [large]="true"
        anchor="right"
        [far]="true"
        [options]="userOptions"
        [secondaryOptions]="userOptionsSecondary"
        [footerOptions]="userOptionsFooter"
        [width]="300"
        (selection)="subMenuClick($event)"
      >
        <div class="cell shrink pointer header__user">
          <div
            class="grid-x"
            [class.align-middle]="!currentAccount?.company?.name"
          >
            <div class="cell shrink">
              <aryel-thumb
                *ngIf="profile"
                size="small"
                [text]="thumbText"
                [image]="thumbImage"
                [disableHover]="true"
              ></aryel-thumb>
            </div>
            <div class="cell auto header__user-text">
              <h5 class="margin margin--none header__name">
                {{ submenuName }}
              </h5>
              <p
                *ngIf="currentAccount?.company?.name"
                class="margin header__account margin--none small"
              >
                {{ currentAccount?.company?.name }}
              </p>
            </div>
          </div>
        </div>
      </aryel-submenu>
    </div>
  </div>
</div>
