import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemeService } from 'src/app/services/theme.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'aryel-forgot-username-card',
  templateUrl: './forgot-username-card.component.html',
  styleUrls: ['./forgot-username-card.component.scss'],
})
export class ForgotUsernameCardComponent {
  loading = false;
  sent = false;
  form: UntypedFormGroup;
  validate = false;

  get logo() {
    return this.themeService.logo;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private toastService: ToastService,
    private themeService: ThemeService
  ) {
    this.form = formBuilder.group({
      email: ['', Validators.required],
    });
  }

  submit(): void {
    this.validate = true;

    if (this.form.valid) {
      this.loading = true;
      this.userService
        .forgotUsername(this.form.get('email').value)
        .subscribe(
          (res) => {
            this.validate = false;
            this.sent = true;
          },
          (err) => {
            this.toastService.addToast(err);
          }
        )
        .add(() => {
          this.loading = false;
        });
    }
  }
}
