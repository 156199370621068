<div *ngIf="authorized === true" class="staging-mode__footer grid-x">
  <div class="cell small-6">
    <p>Staging mode</p>
  </div>
  <div class="cell small-6 staging-mode__signout">
    <p class="text-right">
      <span (click)="signout()">Signout</span>
    </p>
  </div>
</div>
<div class="app" [ngClass]="{ 'no-header': !headerIsVisible }">
  <aryel-toasts-container></aryel-toasts-container>
  <ui-view *ngIf="authorized === true"></ui-view>
  <aryel-fw *ngIf="authorized === false"></aryel-fw>
</div>
