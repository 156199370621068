import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { SubscriptionService } from 'src/app/services/account/subscription.service';
import { TriggerService } from 'src/app/services/account/trigger.service';
import { ITTargetGeneratorService } from 'src/app/services/image-tracking/it-target-generator.service';
import { LockedFeatureService } from 'src/app/services/locked-feature.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { UploadImageTrackingTriggerService } from 'src/app/services/upload/upload-image-tracking-trigger.service';
import { ResourceInterface } from '../../../../types/entities/resource';
import { CompileOutput } from '../../../services/image-tracking/it-target-generator.abstract';

@Component({
  selector: 'aryel-it-target-generator',
  templateUrl: './it-target-generator.component.html',
  styleUrls: ['./it-target-generator.component.scss'],
})
export class ItTargetGeneratorComponent implements OnInit {
  process: CompileOutput = null;
  loading: boolean = false;
  subscription: Subscription = null;
  modalId: string = 'it-target-generator-modal';
  file: File;

  get currentAccount() {
    return this.accountService.currentAccount;
  }

  constructor(
    private iTTargetGeneratorService: ITTargetGeneratorService,
    private uploadImageTrackingTriggerService: UploadImageTrackingTriggerService,
    private triggerService: TriggerService,
    private accountService: AccountService,
    private toastService: ToastService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private lockedFeatureService: LockedFeatureService
  ) {}

  ngOnInit(): void {
    this.subscribe();
  }

  private subscribe() {
    this.subscription = this.iTTargetGeneratorService.process.subscribe(
      (process) => {
        if (!process) {
          if (this.process) {
            this.process = null;
            this.loading = false;
          }
          return;
        }

        this.process = process;
        if (this.process.complete) {
          this.subscription.unsubscribe();

          this.file = process.file;
          this.saveTriggers(process.file);
        }
      },
      (err) => {
        this.error('Error on getting process from it target generator service', err);
      }
    );
  }

  private async saveTriggers(file: File) {
    if (!this.modalService.isOpen(this.modalId)) {
      // Start loading
      this.loading = true;

      // Prepare data to create resource
      const formData = new FormData();
      formData.append('files', file);
      formData.append('account', this.currentAccount._id);

      // Create resource
      this.uploadImageTrackingTriggerService.upload(formData).subscribe(
        async (res: { status: string; message: ResourceInterface }) => {
          if (res.status === 'done') {
            // Resource created
            const file = res.message as ResourceInterface;

            // Binding resource to all triggers of campaign
            this.triggerService
              .imageTrackingResourceBind(
                this.process.triggers.map((trigger) => trigger.id),
                file._id
              )
              .subscribe(
                (res) => {
                  // Binded and all steps completed
                  this.iTTargetGeneratorService.completedWithSuccess();
                  this.complete();
                },
                (err) => {
                  this.error('Error on saving triggers', err);
                }
              );
          }
        },
        (err) => {
          this.error('Error on uploading generated target', err);
        }
      );
    }
  }

  private complete() {
    this.toastService.addToast({
      title: 'Triggers processing complete!',
      status: 'success',
    });
    this.restartProcess();
  }

  private error(errorMsg: string, body: any) {
    console.error(errorMsg, body);

    if (body?.status === 403) {
      this.lockedFeatureService.openPreset('enterprise-out-of-storage');
    }
    this.toastService.addToast({
      title: 'Triggers processing failed!',
      text: 'An error occurred while processing your trigger. Try again or reach us in the help center.',
      status: 'error',
    });
    this.restartProcess();
  }

  restartProcess() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.iTTargetGeneratorService.complete();
    this.process = null;
    this.file = null;
    this.loading = false;
    this.subscribe();
  }

  cancel() {
    this.modalService.openModal(this.modalId);
  }

  confirmCancel() {
    this.iTTargetGeneratorService.complete();
    window.location.reload();
  }

  checkStatus() {
    if (this.process?.complete) {
      this.saveTriggers(this.file);
    }
  }

  closeModal() {
    this.modalService.closeModal(this.modalId);
    this.checkStatus();
  }
}
