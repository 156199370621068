import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AryelUserInterface } from 'src/modules/type-definitions/user';
import { ValidatedInvitationInterface } from 'src/types/entities/account';

@Component({
  selector: 'aryel-join-team',
  templateUrl: './join-team.component.html',
  styleUrls: ['./join-team.component.scss'],
})
export class JoinTeamComponent implements OnInit {
  @Input() user: AryelUserInterface = null;
  @Input() accountId: string = null;
  @Input() emailHash: string = null;
  @Input() invitation: ValidatedInvitationInterface = null;
  @Input() invitationToken: string = null;

  view: 'sign-up' | 'sign-in' | 'logged' = null;

  get currentStateName() {
    return this.stateService.current.name;
  }

  constructor(private stateService: StateService) {}

  ngOnInit(): void {
    this.view = this.user && this.invitation && this.user.email === this.invitation.email ? 'logged' : 'sign-up';
  }
}
