import { Component, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { privilegeOfAdmin } from 'src/app/components/compounds/permissions/role-check';
import { headerSlide } from 'src/app/helpers/animations';
import funnelParams from 'src/app/helpers/parsers/funnel';
import { AccountService } from 'src/app/services/account.service';
import { CommunicationService } from 'src/app/services/account/communication.service';
import { FeatureService } from 'src/app/services/account/feature.service';
import { SubscriptionService } from 'src/app/services/account/subscription.service';
import { HeaderService } from 'src/app/services/header.service';
import { CustomerlyService } from 'src/app/services/integrations/customerly.service';
import { IntercomService } from 'src/app/services/integrations/intercom.service';
import { MeService } from 'src/app/services/me.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionInterface } from 'src/types/entities/subscription';

@Component({
  selector: 'aryel-private',
  templateUrl: './private.component.html',
  animations: headerSlide,
})
export class PrivateComponent implements OnInit {
  pageLoading: boolean = false;
  timeout: any = null;

  pendingSubscription: SubscriptionInterface = null;
  pendingSubscriptionModal: string = 'private-draft-subscription-modal';

  get currentAccount() {
    return this.accountService.currentAccount;
  }

  get headerIsVisible() {
    return this.headerService.isVisible;
  }

  get disabledMobileLock() {
    return this.stateService.current.name === 'private.account-creation';
  }

  constructor(
    private accountService: AccountService,
    private featureService: FeatureService,
    private subscriptionService: SubscriptionService,
    private headerService: HeaderService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private meService: MeService,
    private toastService: ToastService,
    private modalService: ModalService,
    private communicationService: CommunicationService,
    private intercomService: IntercomService,
    private customerlyService: CustomerlyService
  ) {
    this.refreshStorage();
    if (this.meService.me) {
      if (this.meService.me.name && this.meService.me.surname) {
        this.intercomService.start(
          this.meService.me,
          this.accountService.currentAccount || null,
          this.subscriptionService.currentSubscription || null
        );
        this.customerlyService.start(
          this.meService.me,
          this.accountService.currentAccount || null,
          this.subscriptionService.currentSubscription || null
        );
      }
    }

    this.transitionService.onCreate({}, () => this.loadStart());
    this.transitionService.onSuccess({}, () => this.loadEnd());
    this.transitionService.onError({}, () => this.loadEnd());
    this.transitionService.onEnter({ from: '**' }, (transition) => {
      const name = transition.targetState().state().name;
      if (name !== 'private.billing.upgrade-plan') {
        if (this.pendingSubscription) {
          this.managePendingSubcription(this.pendingSubscription);
        }
      }
      if (
        this.currentAccount &&
        name !== 'private.account-creation' &&
        name !== 'private.choose-account' &&
        name !== 'private.sign-out'
      ) {
        if (!this.accountService.currentAccount.subscription) {
          this.stateService.go('private.billing.main');
        }
      }
    });
  }

  ngOnInit() {
    if (
      this.stateService.current.name !== 'private.billing.upgrade-plan' &&
      this.subscriptionService.currentSubscription &&
      (this.stateService.params.plan || this.stateService.params.coupon)
    ) {
      this.stateService.go('private.billing.upgrade-plan', funnelParams(this.stateService));
    }
    this.accountService.currentPendingSubscription.subscribe((pendingSubscription) => {
      if (pendingSubscription) {
        this.managePendingSubcription(pendingSubscription);
      } else {
        this.pendingSubscription = null;
      }
    });
  }

  private loadStart() {
    this.timeout = setTimeout(() => {
      this.pageLoading = true;
    }, 250);
  }
  private loadEnd() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.pageLoading = false;
  }

  private managePendingSubcription(subscription: SubscriptionInterface) {
    // if (subscription.plan.type === 'enterprise') {
    //   this.stateService.go('private.billing.upgrade-plan', {
    //     plan: subscription.plan._id,
    //     pending: subscription._id,
    //   });
    //   this.pendingSubscription = null;
    // } else if (subscription.plan.type === 'standard') {
    // }
    this.pendingSubscription = subscription;
    if (!this.modalService.isOpen(this.pendingSubscriptionModal)) {
      this.modalService.openModal(this.pendingSubscriptionModal, true, true);
    }
  }

  private refreshStorage() {
    forkJoin([
      this.accountService.refreshCurrentAccount(),
      this.subscriptionService.refreshCurrentSubscription(),
      this.featureService.refreshAvailableFeatures(),
    ])
      .pipe(
        tap(() => {
          if (this.currentAccount?._id) {
            this.communicationService.init();
          }
        })
      )
      .subscribe(
        (_) => {
          if (this.currentAccount?._id && privilegeOfAdmin(this.meService.me._id, this.currentAccount)) {
            this.accountService.pendingSubscriptions().subscribe((pendingSubscriptions) => {
              if (pendingSubscriptions.length) {
                this.accountService.setPendingSubscription(pendingSubscriptions[0]);
              }
            });
          }
        },
        (err) => {
          this.toastService.addToast(err);
        }
      );
  }
}
