import { BehaviorSubject, Observable } from 'rxjs';
import 'src/app/libs/image-tracking-compiler/v1/aryel-it-compiler.prod.js';
import 'src/app/libs/image-tracking-compiler/v2/compiler.js';
import { TriggerImageTracking } from '../../../modules/type-definitions/trigger';
import { ITTargetGeneratorTriggersCollectionInterface } from '../../../types/services/it-target-generator';

export interface CompileInput {
  campaign: string;
  triggers: ITTargetGeneratorTriggersCollectionInterface[];
  background: boolean;
  library_version: 'v1' | 'v2';
  file?: File;
}

export interface CompileOutput extends CompileInput {
  complete: boolean;
  progress: number;
  images: HTMLImageElement[];
  success?: boolean;
}

export abstract class ITTargetGenerator {
  protected _process: BehaviorSubject<CompileOutput> = new BehaviorSubject(null);

  get process(): BehaviorSubject<CompileOutput> {
    return this._process;
  }

  protected canvas: HTMLCanvasElement = null;

  protected readonly imageBounds = {
    height: 800,
    width: 800,
  } as const;

  constructor(protected compiler: any) {
    if (!compiler) {
      throw new Error('No compiler found');
    }

    this.canvas = document.createElement('canvas');
  }

  abstract setCompiler(): void;

  abstract prepareInput(...args: any[]): CompileInput;

  abstract compile(input: CompileInput): Observable<CompileOutput>;

  protected abstract resize(file: { [x: string]: any } | File): Observable<HTMLImageElement>;

  protected abstract generateTarget(input: CompileInput): Observable<CompileOutput>;

  /**
   * Given a width and a height, it gives true if the original values
   * has to be resized
   *
   * @param width the original width
   * @param height the original height
   * @param resize the max values for width and height resize
   */
  protected isWidthAndHeightResizable(width: number, height: number, resize: ITTargetGenerator['imageBounds']) {
    return width > resize.width || height > resize.height;
  }

  /**
   * Given a width and a height, it gives the new width and height
   * based on the resize values, which are the maximum values allowed.
   *
   * @param width the original width
   * @param height the original height
   * @param resize the max values for width and height resize
   */
  protected resizeWidthAndHeight(width: number, height: number, resize: ITTargetGenerator['imageBounds']) {
    if (width > height) {
      if (width > resize.width) {
        height = height * (resize.width / width);
        width = resize.width;
      }
    } else {
      if (height > resize.height) {
        width = width * (resize.height / height);
        height = resize.height;
      }
    }

    return { width, height };
  }

  /**
   * Given a TriggerImageTracking, it extract width or height metadata
   */
  protected extractDimensionsMetadata(trigger: TriggerImageTracking, metadata: 'width' | 'height') {
    return parseInt(trigger.payload.resource.main.entry.file.metadata.find((x) => x.key === metadata).value);
  }

  protected bufferToFile(buffer: BlobPart) {
    const blob = new Blob([buffer]);

    return new File([blob], 'targets.aryelit');
  }
}
