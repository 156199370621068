import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize, flatMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ToastService } from 'src/app/services/toast.service';
import { AryelUserInterface } from 'src/modules/type-definitions/user';
import { ValidatedInvitationInterface } from 'src/types/entities/account';
import { Join } from '../join';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';

@Component({
  selector: 'aryel-join-sign-in',
  templateUrl: './join-sign-in.component.html',
  styleUrls: ['./join-sign-in.component.scss'],
})
export class JoinSignInComponent extends Join {
  @Input() user: AryelUserInterface = null;
  @Input() accountId: string = null;
  @Input() emailHash: string = null;
  @Input() invitation: ValidatedInvitationInterface = null;
  @Input() invitationToken: string = null;

  form: UntypedFormGroup;
  validate = false;
  showPassword = false;

  get currentThemeMode() {
    return this.themeService.currentMode;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private stateService: StateService,
    private themeService: ThemeService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
    this.form = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  submit(event: Event) {
    event.preventDefault();
    this.validate = true;
    if (this.form.valid) {
      this.loading = true;
      this.useRecaptcha().pipe(
        flatMap((token) => {
          const body = {
            username: this.form.get('username').value,
            password: this.form.get('password').value,
            invitationTicket: {
              account: this.accountId,
              token: this.invitationToken,
              emailHash: this.emailHash,
            },
            recaptcha: token,
          };
          return this.authService.signin(body);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
        .subscribe(
          () => {
            this.stateService.go('private.choose-account');
          },
          (err) => {
            if (typeof err === 'string') {
              this.toastService.addToast({
                title: 'Something went wrong with the sign-in... Try again or contact us!',
                status: 'error',
              });
            } else {
              this.toastService.addToast(err);
            }
          }
        );
    }
  }

  private useRecaptcha() {
    return environment.recaptcha.v3
      ?
      this.recaptchaV3Service
        .execute('sign_in')
      : of(null)
  }
}
