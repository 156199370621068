import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aryel-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() status: 'primary' | 'secondary' | 'tertiary' | 'white-outline' | 'gray' | 'transparent' = 'primary';
  @Input() type: 'button' | 'submit' = 'button';
  @Input() size: 'small' | 'default' | 'big' = 'default';
  @Input() disabled = false;
  @Input() full: boolean;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() focusIcon: string;
  @Input() link: {
    url: string;
    target?: '_self' | '_blank';
    download?: string;
  };

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {}

  onClick() {
    if (this.link) {
      if (!this.link.download) {
        return window.open(this.link.url, this.link.target || '_self');
      }

      const el = document.createElement('a');
      el.href = this.link.url;
      el.download = this.link.download;

      this.document.body.appendChild(el);
      el.click();
      el.remove();
    }
  }
}
