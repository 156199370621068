import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { AccountService } from 'src/app/services/account.service';
import { FeatureService } from 'src/app/services/account/feature.service';
import { MeService } from 'src/app/services/me.service';
import { ThemeService, ThemeType } from 'src/app/services/theme.service';
import { NavElementInterface } from 'src/types/components/layout/header';
import { SubMenuInterface } from 'src/types/components/layout/submenu';
import { UserInterface } from 'src/types/entities/user';
import { TimeoutType } from 'src/types/utils';
import { privilegeOfAdmin, privilegeOfManager } from '../../compounds/permissions/role-check';

declare global {
  interface Window {
    USETIFUL: {
      tour: {
        getState: () => { id: number; isAvailable: boolean; name: string; state: 'closed' | 'neverRun' | 'inProgress' }[];
        start: (id: number) => void;
        close: () => void;
        // First step is 1
        jumpTo: (index: number) => void;
      };
    };
  }
}

@Component({
  selector: 'aryel-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('logo', [
      transition(':enter', [style({ width: 0, opacity: 0 }), animate('1s ease-in-out', style({ width: '*', opacity: 1 }))]),
    ]),
    trigger('colorMode', [
      transition(':enter', [
        style({ transform: 'translateX(100%) scale(0)' }),
        animate('350ms linear', style({ transform: 'translateX(0%) scale(1)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%) scale(1)' }),
        animate('350ms linear', style({ transform: 'translateX(-100%) scale(0)' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  profile: UserInterface = null;
  thumbText: string = null;

  showLogo: boolean = false;

  navigation: NavElementInterface[] = [
    {
      title: 'Campaigns',
      ref: 'private.campaigns.index',
    },
    {
      title: 'Templates',
      ref: 'private.templates.index',
    },
    {
      title: 'Brand',
      parentRef: 'private.brand',
      children: [
        {
          title: 'Manage Brand Kits',
          text: 'Create and manage your brand kits to be able to easily use a specific style and domain in multiple campaigns.',
          ref: 'private.brandkits.index',
          icon: 'assets/img/illustrations/paint.png',
        },
        {
          title: 'Manage Domains',
          text: 'Connect and manage your custom domains to use with your immersive campaigns.',
          ref: this.featureService.feature<boolean>('brandkits')
            ? 'private.brandkits.domains.index'
            : 'private.brand.domains.index',
          icon: 'assets/img/illustrations/link.png',
        },
        {
          title: 'Media Library',
          text: 'Browse and manage all the media you’ve uploaded: 3D, video, images, and anything that comes to your mind.',
          ref: 'private.brand.media-library',
          icon: 'assets/img/illustrations/3d.png',
        },
      ],
    },
    {
      title: 'Reports',
      ref: 'private.reports',
    },
  ];

  userOptions: SubMenuInterface[] = [];

  userOptionsSecondary: SubMenuInterface[] = [
    {
      text: 'Help Center',
      value: 'help-center',
      href: 'http://jump.aryel.io/help_center',
      target: '_blank',
    },
    {
      text: 'Privacy Policy',
      value: 'privacy',
      href: 'https://aryel.io/privacy-policy/',
      target: '_blank',
    },
    {
      text: 'Terms and Conditions',
      value: 'terms',
      href: 'https://aryel.io/terms-conditions/',
      target: '_blank',
    },
  ];

  userOptionsFooter: SubMenuInterface[] = [
    {
      text: 'Sign out',
      ref: 'private.sign-out',
      color: 'error',
      icon: 'exit_to_app',
    },
  ];

  activeTheme: ThemeType = 'color';

  tourExists: boolean = false;

  get thumbImage() {
    return this.profile.avatar?.main?.thumb?.file.src;
  }

  get me() {
    return this.meService.me;
  }

  get currentAccount() {
    return this.accountService.currentAccount;
  }

  get currentThemeMode() {
    return this.themeService.currentMode;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meService: MeService,
    private themeService: ThemeService,
    private stateService: StateService,
    private featureService: FeatureService,
    private accountService: AccountService,
    private transitionService: TransitionService
  ) {
    let transitionTimeout: TimeoutType = null;

    if (window.USETIFUL) {
      this.transitionService.onStart({ from: '**' }, () => {
        this.tourExists = false;
      });
      this.transitionService.onFinish({ from: '**' }, () => {
        clearTimeout(transitionTimeout);
        transitionTimeout = setTimeout(() => {
          const tours = this.document.defaultView.USETIFUL.tour.getState();
          if (tours.findIndex((tour) => tour.isAvailable) >= 0) {
            this.tourExists = true;
          } else {
            this.tourExists = false;
          }
        }, 1000);
      });
    }
  }

  ngOnInit(): void {
    this.meService.profile().subscribe((res) => {
      this.profile = res;
      this.setThumbText();
    });
    this.generateUserOptions();

    this.featuresNavigationCheck();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showLogo = true;
    });
    if (window.USETIFUL) {
      setTimeout(() => {
        const tours = this.document.defaultView.USETIFUL.tour.getState();
        if (tours.findIndex((tour) => tour.isAvailable) >= 0) {
          this.tourExists = true;
        } else {
          this.tourExists = false;
        }
      }, 1000);
    }
  }

  private featuresNavigationCheck() {
    this.navigation = this.navigation.map((nav) => {
      if (nav.title === 'Brand') {
        if (!this.featureService.feature('brandkits')) {
          nav.children = nav.children.filter((child) => child.ref !== 'private.brandkits.index');
        }
      }
      if (nav.title === 'Brand') {
        if (!privilegeOfManager(this.me._id, this.currentAccount)) {
          nav.children = nav.children.filter((child) => child.ref !== 'private.brandkits.index');
          nav.children = nav.children.filter((child) => child.ref !== 'private.brandkits.domains.index');
        }
      }

      return nav;
    });
  }

  private setThumbText() {
    const label: string[] = [];
    if (this.me?.name || this.me?.surname) {
      if (this.me.name) {
        label.push(this.me.name[0]);
      }
      if (this.me.surname) {
        label.push(this.me.surname[0]);
      }
      return (this.thumbText = label.join(''));
    }
    this.thumbText = this.currentAccount?.company?.name[0] || '';
  }

  private async generateUserOptions() {
    const userOptions: SubMenuInterface[] = [];
    const me = await this.meService.getMe().toPromise();
    const accounts = {
      managedAccounts: me.managedAccounts,
      ownedAccount: me.ownedAccount,
    };
    if (accounts.managedAccounts?.length || !accounts.ownedAccount) {
      userOptions.push({
        text: 'Switch Account',
        ref: 'private.choose-account',
        params: {
          backState: this.stateService.current.name,
        },
        icon: 'compare_arrows',
      });
    }

    userOptions.push(
      {
        text: 'Account',
        ref: 'private.account',
        icon: 'settings',
      },
      {
        text: 'Profile',
        ref: 'private.profile',
        icon: 'person',
      },
      {
        text: 'Users',
        ref: 'private.users.index',
        icon: 'groups',
      }
    );

    if (this.me && this.currentAccount) {
      if (privilegeOfAdmin(this.me._id, this.currentAccount)) {
        userOptions.push({
          text: 'Plans & Billing',
          ref: 'private.billing.main',
          icon: 'payments',
        });
      }
    }
    this.userOptions = userOptions;
  }

  setTheme() {
    if (this.currentThemeMode === 'color') {
      this.themeService.setMode('dark');
    } else {
      this.themeService.setMode('color');
    }
  }

  subMenuClick(event) {}

  get submenuName() {
    const label: string[] = [];
    if (this.me?.name || this.me?.surname) {
      if (this.me.name) {
        label.push(this.me.name);
      }
      if (this.me.surname) {
        label.push(this.me.surname);
      }
      return label.join(' ');
    }
    return this.currentAccount?.company?.name || '';
  }

  startTour() {
    if (!this.tourExists || !window.USETIFUL) {
      return;
    }

    const tours = this.document.defaultView.USETIFUL.tour.getState();
    const availableTour = tours.find((tour) => tour.isAvailable);
    if (availableTour) {
      this.document.defaultView.USETIFUL.tour.start(availableTour.id);
      this.document.defaultView.USETIFUL.tour.jumpTo(1);
    }
  }
}
