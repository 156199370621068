import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FwService } from './fw.service';

@Component({
  selector: 'aryel-fw',
  templateUrl: './fw.component.html',
  styleUrls: ['./fw.component.scss'],
})
export class FwComponent {
  email: string;
  form: UntypedFormGroup;
  emailSent: boolean | string = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private fwService: FwService,
    private localStorageService: LocalStorageService
  ) {
    this.form = this.formBuilder.group({
      email: [''],
      code: [''],
    });
  }

  signinRequestSubmit(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    this.fwService
      .signinRequest(this.form.get('email').value)
      .subscribe()
      .add(() => {
        this.emailSent = this.form.get('email').value;
      });
  }

  signinSubmit(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    this.fwService
      .signin(this.emailSent as string, this.form.get('code').value)
      .subscribe()
      .add(() => {
        location.reload();
      });
  }
}
