import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccountInterface } from 'src/types/entities/account';
import { SubscriptionInterface } from 'src/types/entities/subscription';
import { UserSummaryInterface } from 'src/types/entities/user';

interface IntercomCompanySettingsInterface {
  company_id?: string;
  created_at?: number;
  name?: string;
  plan?: string;
  planVersion?: number;
  'Company type'?: string;
}

interface IntercomSettingsInterface {
  api_base?: string;
  app_id?: string;
  user_id?: string;
  user_hash?: string;
  'Current account'?: string;
  'Owned account'?: string;
  name?: string;
  email?: string;
  company?: IntercomCompanySettingsInterface;
  companies?: IntercomCompanySettingsInterface[];
}

type IntercomEventType = 'reattach_activator' | 'update' | 'boot' | 'shutdown';

declare global {
  interface Window {
    Intercom: (event: IntercomEventType, settings?: IntercomSettingsInterface) => void;
    intercomSettings: IntercomSettingsInterface;
  }
}

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private _booted: boolean = false;

  private settings: IntercomSettingsInterface = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'k3wwhbil',
  };

  get enabled() {
    return environment.intercom.enable && !!window.Intercom;
  }

  get booted() {
    return this._booted;
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}

  start(user: UserSummaryInterface, account?: AccountInterface, subscription?: SubscriptionInterface) {
    if (!this.enabled) {
      return;
    }

    if (this.booted) {
      return;
    }

    this._booted = true;

    window.Intercom('boot', {
      ...this.settings,
      user_id: `${environment.intercom.prefix}${user._id}`,
      user_hash: user.user_hash,
      name: `${user.name} ${user.surname}`,
      email: user.email,
    });

    if (account) {
      this.updateAccounts(user, account, subscription);

      this.setCurrentAccount(user, account);
    }
  }

  setCurrentAccount(user: UserSummaryInterface, account: AccountInterface) {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.Intercom('update', {
      ...this.settings,
      user_id: `${environment.intercom.prefix}${user._id}`,
      user_hash: user.user_hash,
      'Current account': `${environment.intercom.prefix}${account._id}`,
    });
  }

  updateAccounts(user: UserSummaryInterface, account: AccountInterface, subscription?: SubscriptionInterface) {
    if (!this.enabled || !this.booted) {
      return;
    }

    if (!user.ownedAccount && !user.managedAccounts?.length) {
      return;
    }

    if (user.ownedAccount && account._id === user.ownedAccount._id) {
      this.updateCompany(user, {
        company_id: account._id,
        name: account.company.name,
        'Company type': account.company.type,
        ...(subscription ? { plan: subscription.plan.name, planVersion: subscription.plan.version } : {}),
      });
    } else {
      if (!user.managedAccounts?.length) {
        return;
      }

      this.updateCompanies(
        user,
        user.managedAccounts.map((x) => {
          // @ts-ignore
          if (x._id === account._id) {
            return {
              // @ts-ignore
              company_id: x._id,
              name: account.company.name,
              'Company type': account.company.type,
              ...(subscription ? { plan: subscription.plan.name, planVersion: subscription.plan.version } : {}),
            };
          } else {
            return {
              // @ts-ignore
              company_id: x._id,
            };
          }
        })
      );
    }
  }

  updateCompany(user: UserSummaryInterface, company: IntercomCompanySettingsInterface) {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.Intercom('update', {
      ...this.settings,
      user_id: `${environment.intercom.prefix}${user._id}`,
      user_hash: user.user_hash,
      'Owned account': `${environment.intercom.prefix}${company.company_id}`,
      company: {
        ...company,
        company_id: `${environment.intercom.prefix}${company.company_id}`,
      },
    });
  }

  updateCompanies(user: UserSummaryInterface, companies: IntercomCompanySettingsInterface[]) {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.Intercom('update', {
      ...this.settings,
      user_id: `${environment.intercom.prefix}${user._id}`,
      user_hash: user.user_hash,
      companies: companies.map((company) => {
        return {
          ...company,
          company_id: `${environment.intercom.prefix}${company.company_id}`,
        };
      }),
    });
  }

  anonimize() {
    if (!this.enabled || !this.booted) {
      return;
    }

    this._booted = false;

    this.document.defaultView.Intercom('shutdown');
  }
}
