import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import AryelStorage from './storage';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends AryelStorage {
  prefix: string = environment.sessionStorageKey;
  storage: Storage = window.sessionStorage;
}
