import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionInterface } from 'src/types/entities/subscription';

@Component({
  selector: 'aryel-draft-subscription-modal[subscription]',
  templateUrl: './draft-subscription-modal.component.html',
})
export class DraftSubscriptionModalComponent implements OnInit {
  @Input() modalId: string = 'draft-subscription-modal';

  @Input() subscription: SubscriptionInterface;

  constructor(
    private modalService: ModalService,
    private stateService: StateService,
    private accountService: AccountService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  upgrade() {
    this.stateService.go('private.billing.upgrade-plan', {
      plan: this.subscription.plan._id,
      pending: this.subscription._id,
    });
    this.modalService.closeModal(this.modalId);
  }

  remove() {
    this.modalService.lockModal(this.modalId);
    this.accountService
      .removePendingSubscriptions()
      .pipe(finalize(() => this.modalService.unlockModal(this.modalId, true)))
      .subscribe(
        (res) => {
          this.accountService.setPendingSubscription(null);
          this.modalService.closeModal(this.modalId);
        },
        (err) => this.toastService.addToast(err)
      );
  }
}
