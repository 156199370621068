import { Component, Input } from '@angular/core';

@Component({
  selector: 'aryel-tooltip[text]',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() position: 'top' | 'right' | 'bottom' | 'left' = 'top';
  @Input() text: string = '';
  @Input() block: boolean = false;
  @Input() disabled: boolean = false;
  @Input() full: boolean = false;
  @Input() width: string = null;
  @Input() breakLine: boolean = false;
  @Input() size: 'small' | 'default' = 'default';
  @Input() oneLine: boolean = false;

  constructor() {}
}
