import { Component, Input, OnInit } from '@angular/core';
import { RequirementInterface } from 'src/types/components/layout/requirements';

@Component({
  selector: 'aryel-requirements[requirements]',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
})
export class RequirementsComponent implements OnInit {
  @Input() requirements: RequirementInterface[];

  constructor() {}

  ngOnInit(): void {}
}
