import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CampaignFromBlankModalModule } from 'src/app/components/compounds/campaign-from-blank-modal/campaign-from-blank-modal.module';
import { DraftSubscriptionModalModule } from 'src/app/components/compounds/draft-subscription-modal/draft-subscription-modal.module';
import { HeaderModule } from 'src/app/components/layout/header/header.module';
import { ItTargetGeneratorModule } from 'src/app/components/layout/it-target-generator/it-target-generator.module';
import { LoadingModule } from 'src/app/components/layout/loading/loading.module';
import { LockedFeatureModalModule } from 'src/app/components/layout/locked-feature-modal/locked-feature-modal.module';
import { MobileLockModule } from 'src/app/components/layout/mobile-lock/mobile-lock.module';
import { TermsAndConditionsAcceptanceModalModule } from '../../components/compounds/tac-acceptance-modal/terms-and-conditions-acceptance-modal.module';
// import { AccountCreationModule } from './account-creation/account-creation.module';
import { AskContentModule } from './ask-content/ask-content.module';
import { ChooseAccountModule } from './choose-account/choose-account.module';
import { PrivateComponent } from './private.component';
import PrivateRoutes from './private.routes';

@NgModule({
  declarations: [PrivateComponent],
  imports: [
    CommonModule,
    UIRouterModule,
    UIRouterModule.forChild({
      states: PrivateRoutes,
    }),
    ChooseAccountModule,
    AskContentModule,
    HeaderModule,
    MobileLockModule,
    LoadingModule,
    // AccountCreationModule,
    DraftSubscriptionModalModule,
    ItTargetGeneratorModule,
    LockedFeatureModalModule,
    CampaignFromBlankModalModule,
    TermsAndConditionsAcceptanceModalModule,
  ],
})
export class PrivateModule {}
