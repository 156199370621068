
const env = {
    ENVIRONMENT : "staging",

    CAMPAIGN_BASE_URL : "https://staging-app-wonlmuhktq-ez.a.run.app",
    DOUBLE_AUTHENTICATION_LAYER : true,
    LOCAL_STORAGE_KEY : "aryel-cms",
    SESSION_STORAGE_KEY : "aryel-cms",

    API_PROTOCOL : "https",
    API_DOMAIN : "staging-api-wonlmuhktq-ez.a.run.app",
    API_PORT : 443,
    API_BASEPATH : "/v1",

    STRIPE_PUBLIC_KEY : "pk_test_51HOjZ7KHQs18grFT1np2McXLXxA6LBf3YSnE1TafgrHEai0qLw4ITHz6ZSU74uSqqjMMAW5pvYQkBHLXCV3o1xQx00oYgpZeom",

    RECAPTCHA_V3_PUBLIC : "",

    SENTRY_DSN : "",

    EMBED_VIEWER_SCRIPT_URL: "https://staging-aryel-assets.ams3.digitaloceanspaces.com/embed-viewer/aryel-loader.js",

    TEMPLATES_CATEGORIES_ICONS_BASE_URL: "https://staging-aryel-assets.ams3.digitaloceanspaces.com/templates/categories",

    INTERCOM_ENABLE: false,
    INTERCOM_PREFIX: "",

    CUSTOMERLY_ENABLE: true,
    CUSTOMERLY_KEY: "a6fffe85",
};
export default env;
