import { AccountInterface } from 'src/types/entities/account';

const user = (userId: string, account: AccountInterface) => {
  return (
    account.collaborators.find((x) => x.user._id === userId) || {
      role: '',
    }
  );
};

export const isOwner = (userId: string, account: AccountInterface) => {
  return account.owner._id === userId;
};
export const isAdmin = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'admin';
};
export const isManager = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'manager';
};
export const isAuthor = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'author';
};
export const isViewer = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'viewer';
};

export const privilegeOfAdmin = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'admin' || isOwner(userId, account);
};
export const privilegeOfManager = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'manager' || privilegeOfAdmin(userId, account);
};
export const privilegeOfAuthor = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'author' || privilegeOfManager(userId, account);
};
export const privilegeOfViewer = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'viewer' || privilegeOfAuthor(userId, account);
};
