import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'aryel-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit, AfterContentInit {
  @Input() white: boolean;
  @Input() enableHead: boolean;
  @Input() disablePadding: boolean;
  @Input() disableOverflow: boolean;
  @Input() size: 'small' | 'medium' | 'wide' = 'medium';

  @ViewChild('containerHead', { static: true }) head: ElementRef;
  @ViewChild('containerFooter', { static: true }) footer: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    if (!this.head.nativeElement.innerHTML && !this.head.nativeElement.text) {
      this.head.nativeElement.classList.add('container__head--hide');
    }
    if (!this.footer.nativeElement.innerHTML && !this.footer.nativeElement.text) {
      this.footer.nativeElement.classList.add('container__footer--hide');
    }
  }
}
