import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aryel-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit {
  @Input() status: number = 0;
  @Input() size: 'default' | 'small' | 'thin' = 'default';
  @Input() type: 'rounded' | 'flat' = 'rounded';

  constructor() {}

  ngOnInit(): void {}
}
