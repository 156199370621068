import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import 'src/app/libs/image-tracking-compiler/v1/aryel-it-compiler.prod.js';
import 'src/app/libs/image-tracking-compiler/v2/compiler.js';
import { CompileInput, CompileOutput, ITTargetGenerator } from './it-target-generator.abstract';

const ARYELIT = (window as any).ARYEL?.Image;
let COMPILER = null;
let DETECTOR = null;

if (ARYELIT?.Compiler && ARYELIT?.Detector) {
  COMPILER = ARYELIT.Compiler;
  DETECTOR = ARYELIT.Detector;
}

@Injectable({
  providedIn: 'root',
})
export class ITTargetGeneratorV2Service extends ITTargetGenerator {
  static COMPILER = () => {
    return COMPILER && DETECTOR
      ? new COMPILER(DETECTOR, {
          workerPath: '/assets/libs/image-tracking/v2/compiler.worker.js',
        })
      : null;
  };

  constructor() {
    super(ITTargetGeneratorV2Service.COMPILER());
  }

  prepareInput(input: CompileInput) {
    return input;
  }

  setCompiler() {
    this.compiler =
      COMPILER && DETECTOR
        ? new COMPILER(DETECTOR, {
            workerPath: '/assets/libs/image-tracking/v2/compiler.worker.js',
          })
        : null;

    return this.compiler;
  }

  compile(input: CompileInput) {
    return this.resize(input.file).pipe(
      flatMap((image) => {
        window.addEventListener('beforeunload', this.preventClose);

        return this.generateTarget({
          ...input,
          images: [image],
        });
      })
    );
  }

  protected generateTarget(input: CompileInput & { images: HTMLImageElement[] }) {
    return new Observable<CompileOutput>((observer) => {
      observer.next({
        ...input,
        progress: 0,
        complete: false,
      });

      this.compiler
        .compileImageTargets(input.images, async (progress: number) => {
          observer.next({
            ...input,
            progress: Math.floor(progress),
            complete: false,
          });
        })
        .then(
          () => {
            const buffer = this.compiler.exportData() as BlobPart;

            observer.next({
              ...input,
              progress: 100,
              complete: true,
              file: this.bufferToFile(buffer),
            });

            observer.complete();
          },
          (err: Error) => {
            observer.error(err);
          }
        )
        .catch((err: Error) => {
          observer.error(err);
        });
    });
  }

  /**
   * For both V1 and V2
   *
   * it prepares the image for the compiling.
   * This is used to resized the image when necessary and also for the
   * conversion from File object to HTML Image Element
   *
   * @param file an object with info for V1 or a File object for V2
   * @returns
   */
  protected resize(file: File) {
    return new Observable<HTMLImageElement>((observer) => {
      this.resizePromise(file)
        .then(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err);
          }
        )
        .catch((err) => observer.error(err));
    });
  }

  /**
   * It resize the image if
   * one of width or height of the File are greater then resize params
   *
   * @param file A File object containing an Image
   */
  private async resizePromise(file: File) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const resize = this.imageBounds;
      const canvas = this.canvas;
      const resizeWidthAndHeight = this.resizeWidthAndHeight;
      const isWidthAndHeightResizable = this.isWidthAndHeightResizable;

      // First, create the original image from the file to retrieve data
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = function (this: HTMLImageElement) {
        if (isWidthAndHeightResizable(this.naturalWidth, this.naturalHeight, resize)) {
          Object.assign(canvas, resizeWidthAndHeight(this.naturalWidth, this.naturalHeight, resize));

          const context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0, canvas.width, canvas.height);

          const resizedImage = new Image(canvas.width, canvas.height);
          resizedImage.crossOrigin = 'Anonymous';

          resizedImage.onload = () => resolve(resizedImage);
          resizedImage.onerror = reject;

          resizedImage.src = canvas.toDataURL();
        } else {
          resolve(img);
        }

        URL.revokeObjectURL(img.src);
      };
      img.src = URL.createObjectURL(file);
    });
  }

  preventClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
    return;
  }
}
