import { Component, Input, OnInit } from '@angular/core';
import { StatusType } from 'src/types/styles/type';

@Component({
  selector: 'aryel-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  @Input() size: 'large' | 'mlarge' | 'medium' | 'small' = 'medium';
  @Input() color: StatusType | 'white' | 'gradient' = 'gradient';
  @Input() underline: boolean;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() bold: boolean;
  @Input() biggerIcons: boolean;
  @Input() disabled: boolean;
  @Input() link: {
    url: string;
    target?: '_self' | '_blank';
  };

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    if (this.link) {
      return window.open(this.link.url, this.link.target || '_self');
    }
  }
}
