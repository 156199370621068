<aryel-mobile-lock [disabled]="disabledMobileLock">
  <aryel-header [@headerSlide] *ngIf="headerIsVisible"></aryel-header>
  <aryel-loading [loading]="pageLoading" position="fixed">
    <ui-view></ui-view>
    <aryel-draft-subscription-modal
      [modalId]="pendingSubscriptionModal"
      [subscription]="pendingSubscription"
    ></aryel-draft-subscription-modal>
  </aryel-loading>
  <aryel-it-target-generator *ngIf="currentAccount"></aryel-it-target-generator>
  <aryel-locked-feature-modal></aryel-locked-feature-modal>
  <aryel-campaign-from-blank-modal></aryel-campaign-from-blank-modal>
  <aryel-terms-and-conditions-acceptance-modal></aryel-terms-and-conditions-acceptance-modal>
</aryel-mobile-lock>
