import { Transition } from '@uirouter/core';
import { AryelAccountRole } from 'src/modules/type-definitions/account';
import { ToastInterface } from 'src/types/components/layout/toasts';
import { isAdmin, isAuthor, isManager, isOwner, isViewer } from '../components/compounds/permissions/role-check';
import { AccountService } from '../services/account.service';
import { MeService } from '../services/me.service';
import { ToastService } from '../services/toast.service';
import { RouteGuard } from './route-guard';

const defaultRedirect = 'private.home';

export class HasUnauthorizedRoleRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name.indexOf('private') === 0,
  };

  options = {
    priority: 24,
  };

  private redirect: string = defaultRedirect;

  callback = async (transition: Transition) => {
    const futureState: string = transition.to()?.name;
    const previousState: string = transition.from()?.name;
    const isTransitionToHome: boolean = futureState === 'private.home';

    const meService: MeService = transition.injector().get(MeService);
    const toastService: ToastService = transition.injector().get(ToastService);
    const accountService: AccountService = transition.injector().get(AccountService);

    const { stateService } = transition.router;
    const data = transition.targetState().state().data;

    if (data?.rolesConfig && accountService.currentAccount && meService.me) {
      const matchingRedirect: string[] = data?.rolesConfig?.matchingRedirect;
      const unauthorizedRoles: AryelAccountRole[] = data?.rolesConfig?.unauthorizedRoles as AryelAccountRole[];

      const toast: ToastInterface = {
        status: 'error',
        title: 'Access denied!',
        text: 'You don’t have the required permissions to access this area.',
      };

      if (unauthorizedRoles.includes('owner') && isOwner(meService.me._id, accountService.currentAccount)) {
        if (!isTransitionToHome) toastService.addToast(toast);
        return stateService.target(matchingRedirect[unauthorizedRoles.indexOf('owner')] || previousState || this.redirect);
      } else if (unauthorizedRoles.includes('admin') && isAdmin(meService.me._id, accountService.currentAccount)) {
        if (!isTransitionToHome) toastService.addToast(toast);
        return stateService.target(matchingRedirect[unauthorizedRoles.indexOf('admin')] || previousState || this.redirect);
      } else if (unauthorizedRoles.includes('manager') && isManager(meService.me._id, accountService.currentAccount)) {
        if (!isTransitionToHome) toastService.addToast(toast);
        return stateService.target(matchingRedirect[unauthorizedRoles.indexOf('manager')] || previousState || this.redirect);
      } else if (unauthorizedRoles.includes('author') && isAuthor(meService.me._id, accountService.currentAccount)) {
        if (!isTransitionToHome) toastService.addToast(toast);
        return stateService.target(matchingRedirect[unauthorizedRoles.indexOf('author')] || previousState || this.redirect);
      } else if (unauthorizedRoles.includes('viewer') && isViewer(meService.me._id, accountService.currentAccount)) {
        if (!isTransitionToHome) toastService.addToast(toast);
        return stateService.target(matchingRedirect[unauthorizedRoles.indexOf('viewer')] || previousState || this.redirect);
      }
    }
  };
}

export function RouteUnauthorizedRoles(unauthorizedRoles: AryelAccountRole[], matchingRedirect: string[] = []) {
  return {
    unauthorizedRoles: unauthorizedRoles,
    matchingRedirect: matchingRedirect,
  };
}
