import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastActionInterface } from 'src/types/components/layout/toasts';

@Component({
  selector: 'aryel-toast[title]',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() status: 'success' | 'warning' | 'error' = 'success';
  @Input() action: ToastActionInterface = null;
  @Input() timestamp: number;
  @Input() removable: boolean;

  @Output() onRemove: EventEmitter<number> = new EventEmitter();

  get icon() {
    switch (this.status) {
      case 'success':
        return 'done';
      case 'warning':
        return 'warning_amber';
      case 'error':
        return 'close';
      default:
        return 'done';
    }
  }

  constructor() {}

  ngOnInit(): void {}

  remove() {
    if (this.timestamp && this.removable) {
      this.onRemove.emit(this.timestamp);
    }
  }
}
