import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { BadgeModule } from '../../ui/badge/badge.module';
import { LinkModule } from '../../ui/link/link.module';
import { ThumbModule } from '../../ui/thumb/thumb.module';
import { SubmenuModule } from '../submenu/submenu.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { HeaderComponent } from './header.component';
import { NavElementComponent } from './nav-element/nav-element.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';

@NgModule({
  declarations: [HeaderComponent, NavElementComponent, NotificationsMenuComponent],
  imports: [CommonModule, UIRouterModule, SubmenuModule, BadgeModule, ThumbModule, TooltipModule, LinkModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
