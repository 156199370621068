import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account.service';

export const getUploadEventMessage = (event: HttpEvent<any>) => {
  switch (event.type) {
    case HttpEventType.UploadProgress:
      return {
        status: 'progress',
        message: Math.round((100 * event.loaded) / event.total),
      };
    case HttpEventType.Response:
      return {
        status: 'done',
        message: event.body,
      };
    default:
      return {
        status: 'requesting',
        message: '',
      };
  }
};

export const handleUploadError = (error: HttpErrorResponse) => {
  if (error.error instanceof ErrorEvent) {
    console.error('An error occurred:', error.error.message);
  } else {
    console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
  }

  return throwError(error);
};

export abstract class Upload {
  private get basePath() {
    return this.endpoint.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(protected endpoint: string, protected http: HttpClient, protected accountService: AccountService) {}

  public get attachmentsUrl() {
    return '';
  }

  upload(formData: FormData) {
    return this.http
      .post<FormData>(`${environment.api.fullPath}${this.basePath}`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => getUploadEventMessage(event)),
        catchError(handleUploadError)
      );
  }
}
