import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastInterface } from 'src/types/components/layout/toasts';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toasts: BehaviorSubject<ToastInterface[]> = new BehaviorSubject([]);
  private _toastDuration = 5000;

  constructor() {}

  addToast(toast: ToastInterface | Error | HttpErrorResponse) {
    const toasts = this._toasts.value;
    let pushableToast: ToastInterface = null;

    if (toast instanceof Error) {
      pushableToast = {
        title: toast.name,
        text: toast.message,
        status: 'error',
        removable: false,
      };
    } else if (typeof toast === 'object' && 'error' in toast) {
      pushableToast = {
        title: toast.error.name ? toast.error.name : toast.error.message || toast.error.description,
        text: toast.error.name ? toast.error.message || toast.error.description : null,
        status: 'error',
        removable: false,
      };
    } else {
      pushableToast = toast;
      if (pushableToast.removable === undefined) {
        pushableToast.removable = true;
      }
    }

    pushableToast.timestamp = Date.now();

    toasts.push(pushableToast);

    if (toasts.length >= 4) {
      toasts.unshift();
    }

    this._toasts.next(toasts);
    this.scheduleRemoval(pushableToast);
  }

  scheduleRemoval(toast: ToastInterface) {
    setTimeout(() => this.remove(toast), toast.duration || this._toastDuration);
  }

  remove(toast: ToastInterface | number) {
    let match: number = null;
    if (typeof toast === 'number') {
      match = toast;
    } else {
      match = toast.timestamp;
    }
    const toasts = this._toasts.value;
    const found = toasts.findIndex((x) => x.timestamp === match);
    if (found >= 0) {
      toasts.splice(found, 1);
      this._toasts.next(toasts);
    }
  }

  get toasts(): Subject<ToastInterface[]> {
    return this._toasts;
  }
}
