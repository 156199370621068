import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { CardModule } from 'src/app/components/layout/card/card.module';
import { ButtonModule } from 'src/app/components/ui/button/button.module';
import { LinkModule } from 'src/app/components/ui/link/link.module';
import { MeService } from 'src/app/services/me.service';
import { ChooseAccountComponent } from './choose-account.component';

@NgModule({
  declarations: [ChooseAccountComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'private.choose-account',
          url: '/choose-account',
          data: {
            title: 'Switch Account',
          },
          component: ChooseAccountComponent,
          resolve: [
            {
              token: 'backState',
              deps: [Transition],
              resolveFn: (transition: Transition) => {
                return transition.params().backState;
              },
            },
            {
              token: 'accounts',
              deps: [MeService],
              resolveFn: async (meService: MeService) => {
                const me = await meService.getMe().toPromise();

                return {
                  managedAccounts: me.managedAccounts,
                  ownedAccount: me.ownedAccount,
                };
              },
            },
          ],
          params: {
            backState: null,
          },
        },
      ],
    }),
    CardModule,
    ButtonModule,
    LinkModule,
  ],
})
export class ChooseAccountModule {}
