import { Component, Input, OnInit } from '@angular/core';
import { StatusType } from 'src/types/styles/type';

@Component({
  selector: 'aryel-thumb',
  templateUrl: './thumb.component.html',
  styleUrls: ['./thumb.component.scss'],
})
export class ThumbComponent implements OnInit {
  @Input() text: string;
  @Input() image: string;
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  @Input() color: StatusType = 'secondary';
  @Input() disableHover: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
