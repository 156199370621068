import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';
import { formatDate } from 'src/app/helpers/parsers';
import { AccountService } from 'src/app/services/account.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'aryel-ask-content',
  templateUrl: './ask-content.component.html',
  styleUrls: ['./ask-content.component.scss'],
})
export class AskContentComponent implements OnInit {
  loading: boolean = false;

  form: UntypedFormGroup;

  minImages: number = 1;
  maxImages: number = 6;
  images: string[] = [];

  files: File[] = [];

  minDate: string = '';
  date: string = '';
  message: string = '';

  validate: boolean = false;

  imageTips: string[] = [
    'Upload a pic for every relevant view (top, bottom, front, back, and both sides).',
    'Make sure pics are of good quality and avoid too dark/bright or blurry pictures.',
    'Please notice that you can upload a maximum of 6 pics per request.',
    'If available, all the technical drawings and blueprints are welcome.',
  ];

  messageTips: string[] = [
    'The size of the object in real life, to better understand proportions.',
    'The description of the materials and (if applicable) the textures.',
    'Some style references, mood, logo, or brand assets.',
    'The number of elements you want in the scene.',
  ];

  constructor(
    private toastService: ToastService,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private stateService: StateService
  ) {
    this.form = this.formBuilder.group({
      message: ['', Validators.required],
      date: ['', Validators.required],
    });

    /** Scroll top */
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  ngOnInit(): void {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    this.date = formatDate(tomorrow);
    this.form.controls.date.setValue(this.date);
    this.minDate = tomorrow.toISOString();
  }

  async onImageSelect(files: File[]) {
    if (!files.length) {
      return;
    }

    if (this.images.length >= this.maxImages || this.images.length + files.length > this.maxImages) {
      this.toastService.addToast({
        title: `Max ${this.maxImages} images allowed`,
        status: 'error',
      });
      return;
    }

    for (const file of files) {
      let image = null;
      try {
        image = await this.getPreviewImage(file);
      } catch (err) {
        console.error(err);
        this.toastService.addToast({
          title: 'An error occurred...',
          text: 'Try again or contact us at hello@aryel.io',
          status: 'error',
        });
        this.files = [];
        this.images = [];
        break;
      }
      this.images.unshift(image);
      this.files.unshift(file);
    }
  }

  getPreviewImage(file: File) {
    const reader = new FileReader();

    const result: Promise<string> = new Promise((resolve, reject) => {
      reader.onload = (event) => {
        resolve(event.target.result as string);
      };
      reader.onerror = reject;
    });

    reader.readAsDataURL(file);

    return result;
  }

  send() {
    if (this.loading) {
      return;
    }

    this.validate = true;
    if (!this.form.valid || this.files.length < this.minImages) {
      return;
    }
    this.validate = false;

    this.loading = true;
    const data = {
      date: this.date,
      message: this.form.controls.message.value,
    };

    const formData = new FormData();
    for (const file of this.files) {
      formData.append('files[]', file);
    }
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    this.accountService
      .askContent(formData)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (res) => {
          this.toastService.addToast({
            title: 'Request successfully sent',
          });
          this.stateService.go('private.home');
        },
        (err) => {
          this.toastService.addToast({
            title: 'Content ask fail',
            status: 'error',
          });
        }
      );
  }
}
